import React from 'react'
import { navigate } from 'gatsby'

import Footer from '../components/Footer'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/HeroCarousel'
import SectionIntro from '../components/SectionIntro'
import SectionPhone from '../components/SectionPhone'
import SectionSNHD from '../components/SectionSNHD'
import SectionVegasSmart from '../components/SectionVegasSmart'
import Sponsors from '../components/SectionSponsors'

export default () => {
  return (
    <>
      <Layout>
        <SEO />
        <div className="lang">
          <select onChange={e => navigate(`/${e.target.value}`)}>
            <option value="">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
        <Hero lang="en" />
        <SectionIntro lang="en" />
        <SectionPhone lang="en" />
        <SectionSNHD lang="en" />
        <SectionVegasSmart lang="en" />
        <Sponsors lang="en" />
      </Layout>
      <Footer lang="en" />
    </>
  )
}
